import React from "react"
import EventLayout from "../../../components/layouts/eventLayout"
import Button from "../../../components/button"

const CurisinInTheCountryRoutePage = props => (
  <EventLayout
    pageTitle="Cruisin’ in the Country"
    tabs={[
      { to: "/programs/cruisin-in-the-country", text: "Overview" },
      {
        to: "/programs/cruisin-in-the-country/routes",
        text: "Routes",
      },
      {
        to: "/programs/cruisin-in-the-country/registration",
        text: "Registration",
      },
      {
        to: "/programs/cruisin-in-the-country/accomodations",
        text: "Accommodations",
      },
      // {
      //   to: "/programs/cruisin-in-the-country/run",
      //   text: "Run",
      // },
    ]}
  >
    <h2 css={{ marginBottom: `2rem` }}>Route Options</h2>
    <p>
      Cruisin’ is a family friendly bicycle ride. It is not a race. Five different ride options will be offered to ensure
      there is a ride suited for each skill level. Whatever route you choose, you will travel roads with minimal
      traffic and enjoy the beautiful countryside. While riding, you will benefit from SAG vehicles patrolling the
      routes as well as our award-winning, friendly, themed rest stops located every 10 to 20 miles. Rest stops will
      be stocked with water, Power-Ade, and various snacks. In addition, port-a-potties and/or rest rooms, and
      hand-washing stations will be available at each stop.
    </p>
    <br />
    <p>
      Five route options will be offered: 18- mile, 35- mile, 37- mile, 50- mile, 65- mile and the 100- mile, “Royce
      Smith Century Route”. Smith, a former UGA All-American football player, passed away unexpectedly in 2004.
      A co-founder of Cruisin’, he will always be remembered as having put his heart and soul into building this
      event. Maps and cue sheets are available to download and will also be available for pick-up at Cruisin’
      headquarters. You can also access route information via <a href="https://www.active.com/claxton-ga/cycling/races/28th-annual-cruisin-in-the-country-2024">Active.com</a>
    </p>
    <br />
{/*--
    <p>
      <b>Route maps:</b>
    </p>
    <br />

    <ul css={{ marginLeft: `3rem` }}>
      <li>
        <a
          href="https://ridewithgps.com/routes/37940309"
          target="_blank"
          rel="noopener noreferrer"
        >
          Cruisin' in the Country 16 Mile Ride
        </a>
      </li>
      <li>
        <a
          href="https://ridewithgps.com/routes/41352397"
          target="_blank"
          rel="noopener noreferrer"
        >
          Cruisin' in the Country 24 Mile Ride
        </a>
      </li>
      <li>
        <a
          href="https://ridewithgps.com/routes/37940380"
          target="_blank"
          rel="noopener noreferrer"
        >
          Cruisin' in the Country 37 Mile Ride
        </a>
      </li>
      <li>
        <a
          href="https://ridewithgps.com/routes/37940491"
          target="_blank"
          rel="noopener noreferrer"
        >
          Cruisin' in the Country 50 Mile Ride
        </a>
      </li>
      <li>
        <a
          href="https://ridewithgps.com/routes/44828786"
          target="_blank"
          rel="noopener noreferrer"
        >
          Cruisin' in the Country 67 Mile Ride
        </a>
      </li>
      <li>
        <a
          href="https://ridewithgps.com/routes/44829432"
          target="_blank"
          rel="noopener noreferrer"
        >
          Cruisin' in the Country 100 Mile Royce Smith Memorial Century Ride
        </a>
      </li>
    </ul>
  -- */}
  </EventLayout>
)

export default CurisinInTheCountryRoutePage
